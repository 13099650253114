import {
	SearchFilter,
	SearchFilterOptionText,
	SearchFilterOptionTextActive,
} from "../../../redux-magic/sub-interfaces/search-types";

export const residential_options: Array<SearchFilterOptionText> = [
	{
		id: "apartment",
		title: "Apartment",
		value: "Apartment",
	},
	{
		id: "independent_house",
		title: "Independent House",
		value: "Independent House",
	},
	{
		id: "villa",
		title: "Villa",
		value: "Villa",
	},
	{
		id: "plot",
		title: "Plot",
		value: "Plot",
	},
	{
		id: "land_residential",
		title: "Land (Residential)",
		value: "Land (Residential)",
	},
	{
		id: "co_living",
		title: "Co-living",
		value: "Co-living",
	},
	{
		id: "row_house",
		title: "Row House",
		value: "Row House",
	},
	{
		id: "builder_floor_apartment",
		title: "Builder Floor Apartment",
		value: "Builder Floor Apartment",
	},
	{
		id: "penthouse",
		title: "Penthouse",
		value: "Penthouse",
	},
	{
		id: "studio_apartment",
		title: "Studio Apartment",
		value: "Studio Apartment",
	},
];

export const residential_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "apartment",
		title: "Apartment",
		value: "Apartment",
		is_selected: true,
	},
	{
		id: "independent_house",
		title: "Independent House",
		value: "Independent House",
		is_selected: false,
	},
	{
		id: "villa",
		title: "Villa",
		value: "Villa",
		is_selected: false,
	},
	{
		id: "plot",
		title: "Plot",
		value: "Plot",
		is_selected: false,
	},
	{
		id: "land_residential",
		title: "Land (Residential)",
		value: "Land (Residential)",
		is_selected: false,
	},
	{
		id: "co_living",
		title: "Co-living",
		value: "Co-living",
		is_selected: false,
	},
	{
		id: "row_house",
		title: "Row House",
		value: "Row House",
		is_selected: false,
	},
	{
		id: "builder_floor_apartment",
		title: "Builder Floor Apartment",
		value: "Builder Floor Apartment",
		is_selected: false,
	},
	{
		id: "penthouse",
		title: "Penthouse",
		value: "Penthouse",
		is_selected: false,
	},
	{
		id: "studio_apartment",
		title: "Studio Apartment",
		value: "Studio Apartment",
		is_selected: false,
	},
];

export const commercial_options: Array<SearchFilterOptionText> = [
	/*

	* These are property subtypes
	! This is a feature that is slated for future development. Current database queries should only be on the "property_type" field

	*/

	// {
	// 	id: "sez",
	// 	title: "SEZ",
	// 	value: "sez",
	// },
	// {
	// 	id: "educational",
	// 	title: "Educational",
	// 	value: "educational",
	// },
	{
		id: "building",
		title: "Building",
		value: "Building",
	},
	{
		id: "land_commercial",
		title: "Land (Commercial)",
		value: "Land (Commercial)",
	},

	{
		id: "shop",
		title: "Shop",
		value: "Shop",
	},
	{
		id: "office_space",
		title: "Office Space",
		value: "Office Space",
	},
	{
		id: "plot_commercial",
		title: "Plot (Commercial)",
		value: "Plot (Commercial)",
	},
	{
		id: "co_working",
		title: "Co Working",
		value: "Co Working",
	},

	/*

	* These are property subtypes
	! This is a feature that is slated for future development. Current database queries should only be on the "property_type" field

	*/

	// {
	// 	id: "retail",
	// 	title: "Retail",
	// 	value: "retail",
	// },
	// {
	// 	id: "hospitality",
	// 	title: "Hospitality",
	// 	value: "hospitality",
	// },
	// {
	// 	id: "healthcare",
	// 	title: "Healthcare",
	// 	value: "healthcare",
	// },
];

export const commercial_options_active: Array<SearchFilterOptionTextActive> = [
	/*

	* These are property subtypes
	! This is a feature that is slated for future development. Current database queries should only be on the "property_type" field

	*/

	// {
	// 	id: "sez",
	// 	title: "SEZ",
	// 	value: "sez",
	// 	is_selected: false,
	// },
	// {
	// 	id: "educational",
	// 	title: "Educational",
	// 	value: "educational",
	// 	is_selected: false,
	// },
	{
		id: "building",
		title: "Building",
		value: "Building",
		is_selected: false,
	},
	{
		id: "land_commercial",
		title: "Land (Commercial)",
		value: "Land (Commercial)",
		is_selected: true,
	},

	{
		id: "shop",
		title: "Shop",
		value: "Shop",
		is_selected: false,
	},
	{
		id: "office_space",
		title: "Office Space",
		value: "Office Space",
		is_selected: false,
	},
	{
		id: "plot_commercial",
		title: "Plot (Commercial)",
		value: "Plot (Commercial)",
		is_selected: false,
	},
	{
		id: "co_working",
		title: "Co Working",
		value: "Co Working",
		is_selected: false,
	},

	/*

	* These are property subtypes
	! This is a feature that is slated for future development. Current database queries should only be on the "property_type" field

	*/

	// {
	// 	id: "retail",
	// 	title: "Retail",
	// 	value: "retail",
	// 	is_selected: false,
	// },
	// {
	// 	id: "hospitality",
	// 	title: "Hospitality",
	// 	value: "hospitality",
	// 	is_selected: false,
	// },
	// {
	// 	id: "healthcare",
	// 	title: "Healthcare",
	// 	value: "healthcare",
	// 	is_selected: false,
	// },
];

export const agricultural_options: Array<SearchFilterOptionText> = [
	{
		id: "land_agricultural",
		title: "Land (Agricultural)",
		value: "Land (Agricultural)",
	},
	{
		id: "farm_house",
		title: "Farm House",
		value: "Farm House",
	},
	{
		id: "farm_plot",
		title: "Farm Plot",
		value: "Farm Plot",
	},
];

export const agricultural_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "land_agricultural",
		title: "Land (Agricultural)",
		value: "Land (Agricultural)",
		is_selected: true,
	},
	{
		id: "farm_house",
		title: "Farm House",
		value: "Farm House",
		is_selected: false,
	},
	{
		id: "farm_plot",
		title: "Farm Plot",
		value: "Farm Plot",
		is_selected: false,
	},
];

export const warehousing_options: Array<SearchFilterOptionText> = [
	{
		id: "warehouse",
		title: "Warehouse",
		value: "Warehouse",
	},

	/*

	* These are property subtypes
	! This is a feature that is slated for future development. Current database queries should only be on the "property_type" field

	*/

	// {
	// 	id: "warehouse_industrial",
	// 	title: "Warehouse (Industrial)",
	// 	value: "warehouse_industrial",
	// },
	// {
	// 	id: "warehouse_commercial",
	// 	title: "Warehouse (Commercial)",
	// 	value: "warehouse_commercial",
	// },
	// {
	// 	id: "warehouse_cold_storage",
	// 	title: "Warehouse (Cold Storage)",
	// 	value: "warehouse_cold_storage",
	// },
	// {
	// 	id: "warehouse_other",
	// 	title: "Warehouse (Other)",
	// 	value: "warehouse_other",
	// },
];

export const warehousing_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "warehouse",
		title: "Warehouse",
		value: "Warehouse",
		is_selected: true,
	},

	/*

	* These are property subtypes
	! This is a feature that is slated for future development. Current database queries should only be on the "property_type" field

	*/

	// {
	// 	id: "warehouse_industrial",
	// 	title: "Warehouse (Industrial)",
	// 	value: "warehouse_industrial",
	// 	is_selected: false,
	// },
	// {
	// 	id: "warehouse_commercial",
	// 	title: "Warehouse (Commercial)",
	// 	value: "warehouse_commercial",
	// 	is_selected: false,
	// },
	// {
	// 	id: "warehouse_cold_storage",
	// 	title: "Warehouse (Cold Storage)",
	// 	value: "warehouse_cold_storage",
	// 	is_selected: false,
	// },
	// {
	// 	id: "warehouse_other",
	// 	title: "Warehouse (Other)",
	// 	value: "warehouse_other",
	// 	is_selected: false,
	// },
];

export const industrial_options: Array<SearchFilterOptionText> = [
	{
		id: "land_industrial",
		title: "Land (Industrial)",
		value: "Land (Industrial)",
	},
	{
		id: "factory",
		title: "Factory",
		value: "Factory",
	},
	{
		id: "mining",
		title: "Mining",
		value: "Mining",
	},
];

export const industrial_options_active: Array<SearchFilterOptionTextActive> = [
	{
		id: "land_industrial",
		title: "Land (Industrial)",
		value: "Land (Industrial)",
		is_selected: true,
	},
	{
		id: "factory",
		title: "Factory",
		value: "Factory",
		is_selected: false,
	},
	{
		id: "mining",
		title: "Mining",
		value: "Mining",
		is_selected: false,
	},
];

export const _e_property_type: SearchFilter = {
	id: "property_type",
	title: "Property Type",
	level: 2,
	index: 4,
	presentation_type: "radio_buttons",
	multi: false,
	affects: [
		// "bedrooms",
		"bedrooms_variant_2",
		"area",
		// "bathrooms",
		"bathrooms_variant_2",
		// "balconies",
		"balconies_variant_2",
		"seats",
		"plot_dimensions",
		"property_status",
		"unit_facing",
		"land_facing",
		"floors",
		"furnishing",
		"amenities",
	],
	affected_by: ["property_category"],
	priority: 2,
	is_advanced: false,
	included_in_search_bar: true,
	option_type: "text",
	possible_options: [
		...residential_options,
		...commercial_options,
		...agricultural_options,
		...warehousing_options,
		...industrial_options,
	] as Array<SearchFilterOptionText>,
	active_options: [...residential_options_active] as Array<SearchFilterOptionTextActive>,
	/*

	^ Database query should attempt to match the "value" field of the selected option
	^ Database lookup should be performed on the "property_type" (Array<string>) and "property_subtype" (string) fields
	! "property_subtype" is a feature that is slated for future development. Current database queries should only be on the "property_type" field

	*/
	selected_options: [
		{
			id: "apartment",
			title: "Apartment",
			value: "Apartment",
		},
	] as Array<SearchFilterOptionText>,
	db_collection: "properties",
	db_field: "property_type",
	is_visible: true,
	is_applied: true,
	is_accordion_open: true,
};
